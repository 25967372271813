<template>
  <!-- eslint-disable no-mixed-spaces-and-tabs -->
  <div id="main-wrapper">
    <header class="topbar" data-navbarbg="skin5">
          <Header />
      </header>
      <aside class="left-sidebar" data-sidebarbg="skin5">
          <!-- Sidebar scroll-->
          <div class="scroll-sidebar">
              <!-- Sidebar navigation-->
              <nav class="sidebar-nav">
                <Navbar />
              </nav>
            <!-- End Sidebar navigation -->
          </div>
          <!-- End Sidebar scroll-->
      </aside>
      <div class="page-wrapper">
        <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-12 d-flex no-block align-items-center">
                        <h4 class="page-title">Items Listing                           
                        </h4>
                        <div class="ml-auto text-right">
                            <nav aria-label="breadcrumb">
                                <router-link to="/add-inward-items" class="me-2 mdi mdi-basket-fill badge rounded-pill bg-info float-end">Add New</router-link> 
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Sales Cards  -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title m-b-0">Items</h5>
                            </div>
                            <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Identifier</th>
                                      <th scope="col">Item</th>
                                      <th scope="col">No. of units</th>
                                      <th scope="col">Lot count</th>
                                      <th scope="col">Amount</th>
                                      <th scope="col">Date of Entry</th>
                                      <th scope="col">Validity</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="inward in get_inward_items" :key="inward.id">
                                    <tr @click="toggle(inward.id)" :class="{ opened: opened.includes(inward.id) }">
                                      <th scope="row">{{inward.identifier}}</th>
                                      <td>{{inward.item.name}}</td>
                                      <td>{{inward.no_of_units}}</td>
                                      <td>{{inward.lot_count}}</td>
                                      <td>{{inward.amount}}</td>
                                      <td>{{inward.date_of_entry}}</td>
                                      <td>{{inward.validity_in_days}}</td>
                                    </tr>
                                    <!-- lots details -->
                                    <div v-for="lot in inward.lots" :key="lot.id">
                                      <tr v-if="opened.includes(inward.id)">                                      
                                        <td>Lot no: {{lot.id}}</td>
                                        <td>Units: {{lot.no_of_units}}</td>
                                        <td>Amount: {{lot.amount}}</td>
                                      </tr>
                                    </div>
                                  </tbody>
                            </table>
                        </div>
                    </div>                    
                </div>
            </div>
      </div>

      <Footer />
  </div>
  <!-- eslint-disable no-mixed-spaces-and-tabs -->
</template>

<script>
    /* eslint-disable no-mixed-spaces-and-tabs */
      import Header from '@/components/Header';
      import Navbar from '@/components/Nav';
      import Footer from '@/components/Footer';
    export default {
      name: 'inward_items',
        components: {
          Header,
          Navbar,
          Footer
        },
        data() {
            return {
                data : [],
                errors: [],
                opened: []
            }
        },
        computed: {
          get_inward_items() {
            return this.$store.getters.get_inward_items;    
          },     
        },
        mounted() {
          this.$store.dispatch("inward_items");
        },
        methods: {
          toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
              this.opened.splice(index, 1)
            } else {
              this.opened.push(id)
            }
          }
        }
    }
    /* eslint-disable no-mixed-spaces-and-tabs */
</script>